/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Button,
  Form,
  Icon,
  Tag,
  Checkbox,
  Typography,
  message,
  Row,
  Col,
  Switch,
  Input,
  Alert,
} from 'antd';
import { withRouter } from 'react-router-dom';
import { CardElement, injectStripe } from 'react-stripe-elements';
import {
  getSubscriptionNameFromId,
  getSubscriptionDescriptionFromId,
  PLAN_IDS,
  getStripeToken,
  PLANS,
} from '../services/constants';
import EditProfile from './editProfile';
import {
  subscribe,
  getSubscriptionList,
  getProfile,
  logout,
  checkPromoCode,
  upgradePlan,
} from '../services/user';
import { isProfileBillingValid } from '../services/utils';

import '../styles/global.less';
import '../styles/subscriptionDrawer.less';

const SelectorSelected = () => (
  <div className="Selector_outer_circle">
    <div className="Selector_inner_circle" />
  </div>
);
const SelectorNotSelected = () => <div className="Selector_outer_circle" />;
const SelectorDisabled = () => (
  <div className="Selector_cross">
    <Icon type="close" />
  </div>
);

class SubscriptionDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionSelectedId: PLAN_IDS.STARTUP_MONTHLY,
      subscriptions: [],
      loading: false,
      paymentMode: false,
      codePromoInputDisplay: false,
      codePromo: null,
      codePromoValidated: null,
      formComplete: false,
      isEditProfileVisible: false,
      monthlyView: true,
      codePromoLoading: false,
    };
  }

  componentDidMount() {
    getSubscriptionList()
      .then((response) => {
        const { profile } = this.props;
        const currentIndex = response.data.findIndex(
          sub => sub.nameSimple === profile.plan,
        );
        const subs = response.data
          .filter((_, i) => i >= currentIndex)
          .map(sub => ({
            ...sub,
            name: getSubscriptionNameFromId(sub.id),
            description: getSubscriptionDescriptionFromId(sub.id),
          }));
        const monthlyView = profile && profile.stripeSubscription ? !this.isYearly() : true;
        this.setState({
          subscriptions: subs,
          monthlyView,
          subscriptionSelectedId: this.isUpgrade()
            ? monthlyView
              ? PLAN_IDS.PLATINUM_MONTHLY
              : PLAN_IDS.PLATINUM_ANNUALLY
            : monthlyView
              ? PLAN_IDS.STARTUP_MONTHLY
              : PLAN_IDS.STARTER_ANNUALLY,
        });
      })
      .catch((e) => {
        console.error(e);
        message.error('Could not load Subscription list');
      });
  }

  isYearly = () => {
    const { profile } = this.props;
    return profile
      && profile.stripeSubscription
      && profile.stripeSubscription.plan
      ? profile.stripeSubscription.plan.interval === 'year'
      : false;
  };

  getPlanById = id => this.state.subscriptions.find(s => s.id === id);

  getCurrencyCode = currency => (currency === 'eur' ? '€' : '$');

  isSelected = id => id === this.state.subscriptionSelectedId;

  selectSubscription = id => this.setState({ subscriptionSelectedId: id });

  isCreditId = id => id.substring(0, 6) === 'credit';

  getSelectedSubscription = () => {
    const { subscriptions, subscriptionSelectedId } = this.state;
    return subscriptions.find(s => s.id === subscriptionSelectedId);
  };

  paySubscription = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ loading: true });
    this.props.stripe
      .createToken()
      .then(({ token }) => {
        this.afterPaymentCallback(token);
      })
      .catch(() => {
        message.error('Error from payment provider');
        this.setState({ loading: false });
      });
  };

  afterPaymentCallback = (token) => {
    const subscription = this.getSelectedSubscription();
    this.setState({ loading: true });
    const { codePromoValidated, codePromo } = this.state;
    if (this.isUpgrade()) {
      upgradePlan(subscription.id, getStripeToken())
        .then((res) => {
          if (
            res.data.stripeSubscription
            && (res.data.stripeSubscription.status === 'incomplete'
              || res.data.stripeSubscription.status === 'past_due')
            && res.data.stripeSubscription.invoice
            && res.data.stripeSubscription.invoice.hosted_invoice_url
          ) {
            message.error('Payment failed, redirecting to Stripe form...');
            setTimeout(() => {
              window.location.replace(
                res.data.stripeSubscription.invoice.hosted_invoice_url,
              );
            }, 2000);
          } else {
            message.success('Payment done, thank you for your trust.');
            this.checkAccountDataAndRedirect();
          }
        })
        .catch(() => {
          message.error('Error while buying.');
          this.setState({ loading: false });
        });
    } else {
      subscribe(
        subscription.id,
        token.id,
        getStripeToken(),
        codePromoValidated ? codePromo : undefined,
      )
        .then((res) => {
          if (
            res.data.stripeSubscription
            && (res.data.stripeSubscription.status === 'incomplete'
              || res.data.stripeSubscription.status === 'past_due')
            && res.data.stripeSubscription.invoice
            && res.data.stripeSubscription.invoice.hosted_invoice_url
          ) {
            message.error('Payment failed, redirecting to Stripe form...');
            setTimeout(() => {
              window.location.replace(
                res.data.stripeSubscription.invoice.hosted_invoice_url,
              );
            }, 2000);
          } else {
            message.success('Payment done, thank you for your trust.');
            this.checkAccountDataAndRedirect();
          }
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 409) {
            message.error(
              'Error while buying, you already have an active subscription.',
            );
            this.checkAccountDataAndRedirect();
          } else {
            message.error('Error while buying.');
          }

          this.setState({ loading: false });
        });
    }
  };

  // Stripe sometimes take a while to update the object
  checkAccountDataAndRedirect = () => {
    getProfile()
      .then((res) => {
        // Check if profile has a current subscription
        if (
          res.data.stripeSubscription
          && (res.data.stripeSubscription.status === 'active'
            || res.data.stripeSubscription.status === 'trialing')
          && new Date(res.data.stripeSubscription.current_period_end * 1000)
            > new Date()
        ) {
          // Update profile and redirect
          this.props.updateUserProfile().then(() => {
            this.closeDrawer();
          });
        } else {
          setTimeout(this.checkAccountDataAndRedirect, 1000);
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          logout(() => {
            window.location.replace(`${window.location.origin}/#/login`);
          });
        } else {
          console.error(err);
        }
      });
  };

  closeDrawer = () => {
    const { close } = this.props;
    this.setState(
      prevState => ({
        subscriptionSelectedId: this.isUpgrade()
          ? prevState.monthlyView
            ? PLAN_IDS.BUSINESS_MONTHLY
            : PLAN_IDS.BUSINESS_ANNUALLY
          : prevState.monthlyView
            ? PLAN_IDS.STARTUP_MONTHLY
            : PLAN_IDS.STARTUP_ANNUALLY,
        loading: false,
        paymentMode: false,
        codePromoInputDisplay: false,
        codePromo: null,
        codePromoValidated: null,
        formComplete: false,
      }),
      () => close(),
    );
  };

  onCardElementChange = e => this.setState({ formComplete: e.complete });

  choosePlan = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      this.state.subscriptionSelectedId === PLAN_IDS.DATABASE_A
      || this.state.subscriptionSelectedId === PLAN_IDS.DATABASE_M
    ) {
      window.open('https://buy.stripe.com/cN2aItdrA2rV17GcMN');
      this.closeDrawer();
    }
    this.props.form.validateFields((err) => {
      if (!err) {
        if (isProfileBillingValid(this.props.profile)) {
          this.setState({ paymentMode: true });
        } else {
          this.setState({ isEditProfileVisible: true });
        }
      }
    });
  };

  closeEditProfile = () => {
    this.props.updateUserProfile().then(() => this.setState({
      isEditProfileVisible: false,
      paymentMode: !!isProfileBillingValid(this.props.profile),
    }));
  };

  onChangeMonthlyView = val => this.setState(prevState => ({
    monthlyView: val,
    subscriptionSelectedId: !prevState.monthlyView
      ? PLAN_IDS.STARTUP_MONTHLY
      : PLAN_IDS.STARTUP_ANNUALLY,
  }));

  isBusinessPlan = id => id === PLAN_IDS.BUSINESS_MONTHLY || id === PLAN_IDS.BUSINESS_ANNUALLY;

  isGoldPlan = id => id === PLAN_IDS.GOLD_MONTHLY || id === PLAN_IDS.GOLD_ANNUALLY;

  displayCodePromoInput = () => this.setState({ codePromoInputDisplay: true });

  handleChangeCodePromo = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ codePromo: e.target.value.toUpperCase() });
  };

  validateCodePromo = () => {
    this.setState({ codePromoLoading: true });
    checkPromoCode(this.state.codePromo)
      .then(response => this.setState({ codePromoValidated: response.data }, () => {
        message.success('Promo code added');
      }))
      .catch((error) => {
        if (error && error.response && error.response.status === 404) {
          message.error('This promo code does not exist.');
        } else {
          message.error('Error while adding the promo code, please try again.');
        }
      })
      .finally(() => {
        this.setState({ codePromoLoading: false });
      });
  };

  changeCodePromo = () => this.setState({ codePromo: null, codePromoValidated: null });

  getPriceToPay = () => {
    const { codePromoValidated, monthlyView } = this.state;
    // const { profile } = this.props;
    // if (this.isUpgrade()) {
    //   const now = new Date();
    //   const startSub = new Date(profile.stripeSubscription.current_period_start * 1000);
    //   const endSub = new Date(profile.stripeSubscription.current_period_end * 1000);
    //   const selectedPrice = this.isYearly() ? this.getSelectedSubscription().price * 12 : this.getSelectedSubscription().price;
    //   const cost = ((endSub - now) / (endSub - startSub)) * (selectedPrice - profile.stripeSubscription.items.data[0].plan.amount);
    //   return (cost / 100).toFixed(0);
    // }
    return codePromoValidated
      ? (monthlyView
        ? codePromoValidated.percent_off
          ? this.getSelectedSubscription().price / 100
              - (this.getSelectedSubscription().price / 10000)
                * codePromoValidated.percent_off
          : this.getSelectedSubscription().price / 100
              - codePromoValidated.amount_off / 100
        : codePromoValidated.percent_off
          ? (this.getSelectedSubscription().price / 100
              - (this.getSelectedSubscription().price / 10000)
                * codePromoValidated.percent_off)
            * 12
          : (this.getSelectedSubscription().price / 100) * 12
            - codePromoValidated.amount_off / 100
      ).toFixed(2)
      : monthlyView
        ? this.getSelectedSubscription().price / 100
        : (this.getSelectedSubscription().price / 100) * 12;
  };

  isUpgrade = () => {
    const { profile } = this.props;
    return (
      profile.plan
      && (profile.plan === PLANS.STARTUP || profile.plan === PLANS.BUSINESS_V3)
      && profile.stripeSubscription
      && profile.stripeSubscription.items
      && profile.stripeSubscription.items.data[0]
      && profile.stripeSubscription.items.data[0].plan
    );
  };

  render() {
    const {
      loading,
      paymentMode,
      subscriptions,
      codePromoValidated,
      codePromoLoading,
      codePromoInputDisplay,
      codePromo,
      formComplete,
      isEditProfileVisible,
      monthlyView,
      subscriptionSelectedId,
    } = this.state;
    const { isOpen, profile } = this.props;
    const { getFieldDecorator } = this.props.form;
    // if (!paymentMode && this.state.subscriptions.length === 1) {
    //   this.selectSubscription(this.state.subscriptions[0].id);
    //   this.choosePlan();
    // }
    return (
      <Drawer
        placement="right"
        closable
        width={600}
        title="Subscription"
        visible={isOpen}
        onClose={loading ? null : this.closeDrawer}
        className="Subscription_modal"
        loading={loading}
      >
        {profile.stripeSubscription
        && (profile.stripeSubscription.status === 'active'
          || profile.stripeSubscription.status === 'trialing')
        && new Date(profile.stripeSubscription.current_period_end * 1000)
          > new Date()
        && profile.plan !== PLANS.STARTUP
        && profile.plan !== PLANS.BUSINESS_V3 ? (
          <div>
            <Alert
              type="warning"
              showIcon
              message={(
                <>
                  You already have a plan and cannot upgrade, please checkout
                  our
                  {' '}
                  <a
                    href="https://tokfluence.com/pricing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    pricing page
                  </a>
                  {' '}
                  and contact us for any question: contact@tokfluence.com
                </>
)}
            />
          </div>
          ) : (
            <>
              <div className="Subscription_modal_wrapper">
                {paymentMode && (
                <div>
                  <Button
                    icon="left"
                    onClick={() => {
                      this.setState({
                        paymentMode: false,
                        codePromoInputDisplay: false,
                        codePromo: null,
                        codePromoValidated: null,
                      });
                    }}
                    type="link"
                    size="small"
                  >
                    Change
                  </Button>
                </div>
                )}
                {!paymentMode && (
                <div>
                  <div className="flex" style={{ justifyContent: 'center' }}>
                    <div className="Monthly_toggle_wrapper">
                      <div
                        className={`center bold ${monthlyView ? 'grey' : ''}`}
                      >
                        PAY ANNUALLY
                        <br />
                        <span className="Save_money red">SAVE 50% !</span>
                      </div>
                      <Switch
                        className="Monthly_toggle"
                        onChange={this.onChangeMonthlyView}
                        checked={monthlyView}
                        disabled={this.isUpgrade()}
                      />
                      <div className={`bold ${monthlyView ? '' : 'grey'}`}>
                        PAY MONTHLY
                      </div>
                    </div>
                  </div>
                  <div className="center">
                    <a
                      href="https://tokfluence.com/pricing"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button type="link">
                        <i>See Pricing page for all details on the plans</i>
                      </Button>
                    </a>
                  </div>
                </div>
                )}
                {!paymentMode && (
                <div className="Want_buy_credit_wrap title normal center">
                  <div>
                    <a href="/#/reports/buy-credits">
                      <Icon type="radar-chart" />
                      {' '}
click here to buy credits for
                      audience reports
                    </a>
                  </div>
                </div>
                )}
                {subscriptions
                  .filter(s => (paymentMode
                    ? s.id === subscriptionSelectedId
                    : s.monthly === monthlyView))
                  .map(s => (
                    <div
                      key={s.id}
                      className="Subscription_list_element"
                      onClick={() => (s.price && profile.plan !== s.nameSimple
                        ? this.selectSubscription(s.id)
                        : null)
                    }
                    >
                      <div className="Subscription_list_selector">
                        {s.price && profile.plan !== s.nameSimple ? (
                          this.isSelected(s.id) ? (
                            <SelectorSelected />
                          ) : (
                            <SelectorNotSelected />
                          )
                        ) : (
                          <SelectorDisabled />
                        )}
                      </div>
                      <div className="Subscription_element_content">
                        <div className="Subscription_element_title title">
                          {s.name}
                        </div>
                        <div className="Subscription_element_subtitle">
                          {s.description}
                        </div>
                      </div>
                      {this.isGoldPlan(s.id) && (
                      <Tag color="volcano" className="Subscription_popular">
                        Popular
                      </Tag>
                      )}
                      <div className="Subscription_element_price title">
                        <div>
                          {s.price ? (
                            <>
                              <span>
                                {`${this.getCurrencyCode(s.currency)}${
                                  s.price / 100
                                }`}
                              </span>
                            </>
                          ) : (
                            <div className="Contact_us_pricing">
                              <div>Contact us</div>
                              <div style={{ fontSize: '12px', color: '#7070fd' }}>
                              at
                                {' '}
                                <span style={{ textDecoration: 'underline' }}>
                                  <a href="mailto:contact@tokfluence.com">
                                  contact@tokfluence.com
                                  </a>
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        {' '}
                        {s.price
                        && s.id !== PLAN_IDS.DATABASE_A
                        && s.id !== PLAN_IDS.DATABASE_M && (
                          <div className="Subscription_element_price_month">
                            / Month
                          </div>
                        )}
                        {!monthlyView
                        && s.price
                        && s.id !== PLAN_IDS.DATABASE_A
                        && s.id !== PLAN_IDS.DATABASE_M && (
                          <div style={{ fontSize: '12px' }}>
                            $
                            {(s.price / 100) * 12}
                            {' '}
per year
                          </div>
                        )}
                        {/* {codePromoValidated && (
                  <div className="Subscription_element_promo_code">
                    <Tag color="green">{`-${codePromoValidated.percent_off
                      ? `${codePromoValidated.percent_off}%`
                      : `${codePromoValidated.amount_off}$`}`}</Tag>
                  </div>
                  )} */}
                        {!monthlyView
                        && s.price
                        && s.id !== PLAN_IDS.DATABASE_A
                        && s.id !== PLAN_IDS.DATABASE_M && (
                          <div className="Subscription_element_promo_code">
                            <Tag color="green">Save 50%</Tag>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}

                {!paymentMode && (
                <Form className="Payment_form_checkbox">
                  <Form.Item>
                    {getFieldDecorator('agreement', {
                      valuePropName: 'checked',
                      initialValue: false,
                      rules: [
                        {
                          required: true,
                          message: 'You must accept the terms of sales.',
                          transform: value => value || undefined,
                          type: 'boolean',
                        },
                      ],
                    })(
                      <Checkbox>
                        I accept the
                        {' '}
                        <a
                          href="https://tokfluence.com/terms-conditions"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms of sales
                        </a>
                        .
                      </Checkbox>,
                    )}
                  </Form.Item>
                </Form>
                )}
                {paymentMode && (
                <div
                  className={`Subscription_payment_wrapper ${
                    this.isUpgrade() ? 'Display_none' : ''
                  }`}
                >
                  <Typography.Text strong>
                    Enter your payment information here:
                  </Typography.Text>
                  <div>
                    <Typography.Text type="secondary">
                      <i>Form secured by Stripe</i>
                    </Typography.Text>
                  </div>
                  <CardElement onChange={this.onCardElementChange} />

                  <div className="center" style={{ marginTop: '12px' }}>
                    <img
                      className="Payment_stripe_logo"
                      src="img/stripe.png"
                      alt="secured by stripe"
                    />
                  </div>

                  {subscriptionSelectedId !== PLAN_IDS.STARTUP_MONTHLY
                    && !this.isUpgrade() && (
                      <div className="center Code_promo_wrapper">
                        {codePromoInputDisplay ? (
                          <Row gutter={4}>
                            <Col span={16}>
                              <Input
                                type="text"
                                placeholder="Enter a promotion code"
                                onChange={this.handleChangeCodePromo}
                                disabled={!!codePromoValidated}
                                value={codePromo}
                              />
                            </Col>
                            <Col span={8}>
                              {codePromoValidated ? (
                                <Button
                                  className="small width-100"
                                  type="primary"
                                  onClick={this.changeCodePromo}
                                  ghost
                                >
                                  Change
                                </Button>
                              ) : (
                                <Button
                                  loading={codePromoLoading}
                                  disabled={!codePromo}
                                  className="small width-100"
                                  type="primary"
                                  onClick={this.validateCodePromo}
                                >
                                  Validate
                                </Button>
                              )}
                            </Col>
                          </Row>
                        ) : (
                          <Button
                            type="link"
                            className="small"
                            onClick={this.displayCodePromoInput}
                          >
                            I have a promo code
                          </Button>
                        )}
                      </div>
                  )}
                </div>
                )}
              </div>

              <div className="Payment_drawer_footer">
                {paymentMode ? (
                  <Button
                    htmlType="submit"
                    key="submit"
                    type="primary"
                    size="large"
                    className="Subscription_submit_modale"
                    disabled={!formComplete && !this.isUpgrade()}
                    onClick={this.paySubscription}
                    loading={loading}
                  >
                    {`Pay ${this.getPriceToPay()}${this.getCurrencyCode(
                      this.getSelectedSubscription().currency,
                    )}`}
                  </Button>
                ) : (
                  <Button
                    htmlType="submit"
                    key="submit"
                    type="primary"
                    size="large"
                    onClick={this.choosePlan}
                    className="Subscription_submit_modale"
                  >
                  Choose
                    {' '}
                    {subscriptions.length > 0
                    && this.getSelectedSubscription().name}
                  </Button>
                )}
              </div>
            </>
          )}
        {profile && (
          <EditProfile
            profile={profile}
            isVisible={isEditProfileVisible}
            close={this.closeEditProfile}
          />
        )}
      </Drawer>
    );
  }
}

SubscriptionDrawer.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  form: PropTypes.object,
  stripe: PropTypes.object,
  updateUserProfile: PropTypes.func,
  profile: PropTypes.object,
};

SubscriptionDrawer.defaultProps = {
  isOpen: false,
  close: null,
  form: null,
  stripe: {},
  profile: null,
  updateUserProfile: () => {},
};

export default Form.create({ name: 'payment' })(
  withRouter(injectStripe(SubscriptionDrawer)),
);
